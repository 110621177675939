import { sdk } from 'Services/shelfNetworkSdk'
import { mutations, actions } from './types'
import { userGetters } from 'Store/entities/User/types'

export default {
  async [actions.LOAD_LOT_BY_ID] ({ commit }, id) {
    const { data: lot } = await sdk.horizon.lots.get(id)
    commit(mutations.SET_LOT, lot)
  },

  async [actions.LOAD_TRANSPORTATION] ({ getters, rootGetters, commit }) {
    const {
      branch,
      city,
      country,
      state,
      zip
    } = getters.lot.details

    const { data } = await sdk.w8TransFee.calculateTransportationFees({
      filter: {
        departureCity: city,
        departureState: state,
        departureCountry: country,
        departureZipCode: zip || undefined,
        departureBranchId: Number(branch) || undefined,
        lotType: getters.lot.lotTypeStr.replace('lot_type_', '')
      },
      include: [
        'locations',
        ...(rootGetters[`entities/user/${userGetters.IS_ADMIN}`]
          ? ['fee_details']
          : []
        )
      ]
    })

    commit(mutations.SET_TRANSPORTATION, data[0])
  }
}
