import { mutations } from './types'

export default {
  [mutations.SET_LOT] (state, lot) {
    state.lot = lot
  },

  [mutations.SET_TRANSPORTATION] (state, transportation) {
    state.transportation = transportation
  }
}
